<template>
  <v-layout shrink :class="vertical ? 'column' : 'align-center'">
    <div :class="['text-subtitle-1 black--text ml-4', vertical ? 'mb-1' : '']">{{ label }}</div>
    <v-layout column>
      <v-select
        class="pa-0"
        v-model="session"
        :items="sessions"
        :disabled="disabled"
        no-data-text="Brak terapi"
        :hide-details="true"
        return-object
        :item-text="x => x.name"
        outlined
        :loading="loading"
        :class="['default-input-style', 'mx-4', vertical ? '' : 'zg-ss-horizontal']"
        :menu-props="{contentClass:'zg-select-border-radius'}"
        append-icon="mdi-chevron-down"
        :offset-y="true"
        dense
      ></v-select>
    </v-layout>
  </v-layout>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";
import { mapGetters } from "vuex";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    label: {},
    vertical: {
      default: true
    },
    patientId: {
      default: undefined
    },
    disabled: {
      default: false
    },
    predefinedValue: {
      default: undefined
    }
  },
  data() {
    return {
      sessions: [this.predefinedValue]
    };
  },
  computed: {
    session: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  methods: {
    fetchData() {
      if (this.patientId == undefined) {
        return;
      }
      this.beforeRequest();
      PatientService.getPatientCards()
        .then(x => {
          if (this.predefinedValue == undefined) this.sessions = x;
          else this.sessions = [this.predefinedValue, ...x];
          this.reset();
        })
        .catch(x => {
          console.log(x);
          this.handleError(x);
        });
    }
  },
  created() {
    if (this.predefinedValue != undefined) {
      this.sessions = [this.predefinedValue];
    }
    this.fetchData();
  }
};
</script>

<style lang="scss">
.zg-ss-horizontal .v-input__slot {
  margin-top: 3px !important;
}
</style>
